import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataService/data.service';

@Component({
  selector: 'app-user-grid',
  templateUrl: './user-grid.component.html',
  styleUrls: ['./user-grid.component.css'],
})
export class UserGridComponent implements OnInit {
  cols: any;
  usersData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  country: any = [];
  role: any = [];
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.generateHeaders();
    this.loadData();
    this.selectedCols = JSON.parse(JSON.stringify(this.cols));
    this.orgCol = JSON.parse(
      JSON.stringify(this.cols.filter((x: any) => x.is_show))
    );
  }

  selectedCols: any[] = [];
  orgCol: any;
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(
      JSON.stringify(this.dataService.sortSelectedColumns(this.selectedCols))
    );
  }

  exportExcel() {
    let tempUserdata = _.cloneDeep(this.usersData);
    const tempSample = tempUserdata.map((item: any) => {
      if (item.id) {
        delete item.id;
      }

      Object.keys(item).forEach((key: string) => {
        let keyName = this.cols.filter((item: any) => item.field == key);
        keyName = keyName.length > 0 ? keyName[0].header : key;
        item[keyName] = item[key] ? item[key].toString() : '';
        if (keyName.length > 0) {
          delete item[key];
        }
      });

      return { ...item };
    });
    this.dataService.exportExcel(tempSample, 'User');
  }

  loadData() {
    this.spinner.show();
    this.dataService.GetAllUsers().subscribe((response) => {
      this.spinner.hide();
      if (!response['IsError']) {
        let tempUserData = response.response;
        tempUserData = tempUserData.map((item: any) => {
          return { ...item, isAdmin: item.isAdmin ? 'Yes' : 'No' };
        });
        this.usersData = tempUserData;
      }
    });
  }
  onSelectRow(rowObject: { rowData: any; type: any }) {
    if (rowObject.type == 'delete') {
      this.onDeleteRow(rowObject);
      return;
    }
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));
    this.selectedRow = {
      ...this.selectedRow,
      isAdmin: this.selectedRow.isAdmin == 'Yes' ? true : false,
    };

    this.formType = 'Edit';
    this.showForm = true;
  }
  onDeleteRow(rowObject: { rowData: any; type: any }) {
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));
    this.dataService.DeleteUser(this.selectedRow.id).subscribe({
      next: (response) => {
        this.spinner.hide();
        if (!response['IsError']) {
          this.toastr.success('User deleted successfully.');
          this.loadData();
        }
      },
      error: (error) => {
        this.toastr.warning(error.error);
      },
    });
  }
  generateHeaders() {
    this.cols = [
      {
        serial_no: 1,
        field: 'basfUserId',
        header: 'BASF User ID',
        is_show: true,
        //basg id
        is_editable: true,
      },
      {
        serial_no: 2,
        field: 'firstName',
        header: 'First Name',
        is_show: true,
        //first anem
        is_editable: true,
      },
      {
        serial_no: 3,
        field: 'lastName',
        header: 'Last Name',
        is_show: true,
        //last name
        is_editable: true,
      },
      {
        serial_no: 4,
        field: 'email',
        header: 'Email',
        is_show: true,
        //email
        is_editable: true,
      },
      {
        serial_no: 5,
        field: 'isAdmin',
        header: 'Is Admin',
        is_show: true,
        //is active
        is_editable: true,
      },
    ];
  }
  addNewUser() {
    this.selectedRow = {
      basfUserId: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      isAdmin: false,
    };
    this.formType = 'New';
    this.showForm = true;
  }

  refreshUsers(eventvalue: any) {
    if (eventvalue) {
      this.loadData();
    }
    this.showForm = false;
  }
}
