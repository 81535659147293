<div class="row">
  <div class="col-md-12">
    <form id="userRegistrationForm" [formGroup]="userRegistrationForm">
      <div class="row">
        <div class="col-md-12">
          <div style="align-items: inherit">
            <div class="row">
              <div class="col-md-6 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="basfUserName"
                    >Basf User Id<sup
                      style="color: red; font-weight: 900; font-size: 12px"
                      ><b>*</b></sup
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="basfUserId"
                  />
                </div>
              </div>
              <div class="col-md-6 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="email"
                    >Email<sup
                      style="color: red; font-weight: 900; font-size: 12px"
                      ><b>*</b></sup
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="emailAddress"
                  />
                </div>
              </div>
              <div class="col-md-6 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="firstName"
                    >First Name<sup
                      style="color: red; font-weight: 900; font-size: 12px"
                      ><b>*</b></sup
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="firstName"
                  />
                </div>
              </div>
              <div class="col-md-6 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="lastName"
                    >Last Name<sup
                      style="color: red; font-weight: 900; font-size: 12px"
                      ><b>*</b></sup
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="lastName"
                  />
                </div>
              </div>
              <div class="col-md-12 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="isAdmin"
                    >Is Admin<sup
                      style="color: red; font-weight: 900; font-size: 12px"
                      ><b>*</b></sup
                    ></label
                  >
                  <p-checkbox
                    [(ngModel)]="isAdmin"
                    [binary]="true"
                    formControlName="isAdmin"
                    inputId="binary"
                  >
                  </p-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mobileButtonClass bottom-class">
                <div
                  class="row"
                  style="
                    align-items: inherit;
                    text-align: center;
                    padding: 20px 0px;
                  "
                >
                  <div class="col-md-12 cust-form-group">
                    <div>
                      <input
                        type="button"
                        value="Cancel"
                        class="btn cancel-btn"
                        (click)="cancel()"
                      />
                      <input
                        type="button"
                        value="Submit"
                        class="btn submit-btn"
                        (click)="submitUser()"
                        [disabled]="userRegistrationForm.invalid"
                        (click)="
                          userRegistrationForm.invalid ? toastrAlert() : ''
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>
