<div class="side-padding" id="sampleRegistration">
  <div>
    <div class="row" style="align-items: center; padding: 20px 0px">
      <label class="col-md-6 header-label">Available Samples</label>
    </div>
    <div class="row" style="background: white; margin: 0px">
      <div
        class="row"
        style="align-items: center; padding: 20px 0px; margin: 0px"
        [ngClass]="{ 'modal-backdrop-custom': showForm || showPallet }"
      >
        <div class="col-md-6 mobileButtonClass">
          <button
            *ngIf="!showForm"
            type="button"
            class="btn add-btn"
            (click)="addNewSample()"
          >
            Add New Sample
          </button>
          <button
            *ngIf="!showForm"
            type="button"
            (click)="exportExcel()"
            class="btn excelButton"
          >
            <em class="pi pi-file-excel"></em>Export
          </button>
        </div>
        <div class="col-md-6" style="text-align: end">
          <button
            type="button"
            class="btn add-btn"
            style="margin: 0px 10px; border-radius: 50px !important"
            (click)="showPallet = true; showForm = false"
          >
            Pallet Configuration
          </button>
          <p-multiSelect
            id="showGridClass"
            [options]="orgCol"
            [(ngModel)]="selectedCols"
            name="colsDD"
            ngDefaultControl
            (onChange)="onChangeDDValue($any($event).value)"
            optionLabel="header"
            defaultLabel="Show items in grid"
            [displaySelectedLabel]="false"
          >
          </p-multiSelect>
        </div>
      </div>

      <div class="col-md-12" style="position: relative">
        <div *ngIf="showPallet" class="custom-sidenav">
          <app-configuration
            (showPallet)="onHidePallet($event)"
          ></app-configuration>
        </div>
        <div *ngIf="showForm" class="custom-sidenav">
          <div class="row" style="align-items: center; color: white">
            <label class="col-md-10 col-sm-10 col-10 header-label"
              >{{ formType }} Sample</label
            >
            <span
              class="col-md-2 col-sm-2 col-2"
              (click)="showForm = false"
              style="cursor: pointer; padding: 0px; text-align: center"
              >X</span
            >
          </div>

          <div
            class="row"
            [ngClass]="{ 'add-poniter-all': showForm || showPallet }"
          >
            <div class="col-md-12">
              <app-sample-registration
                [formData]="selectedRow"
                [formType]="formType"
                [data]="{country,role}"
                (reLoadGrid)="refreshUsers($event)"
              >
              </app-sample-registration>
            </div>
          </div>
        </div>
        <div
          class="row"
          [ngClass]="{ 'modal-backdrop-custom': showForm || showPallet }"
        >
          <div class="col-md-12">
            <app-dynamic-grid
              [gridCols]="cols"
              [gridData]="sampleData"
              [showFilter]="true"
              [showEdit]="true"
              (selectRow)="onSelectRow($event)"
            >
            </app-dynamic-grid>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>
