import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { DatePipe } from '@angular/common';

import { DialogModule } from 'primeng/dialog';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { DynamicGridComponent } from './components/shared/dynamic-grid/dynamic-grid.component';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { AdminComponent } from './components/admin/admin.component';
import { SampleRegistrationComponent } from './components/admin/sample/sample-registration/sample-registration.component';
import { SampleGridComponent } from './components/admin/sample/sample-grid/sample-grid.component';
import { LocateSampleComponent } from './components/admin/locateSample/locate-sample/locate-sample.component';
import { ExpiringSampleComponent } from './components/admin/expiringSample/expiring-sample/expiring-sample.component';
import { UserGridComponent } from './components/admin/users/user-grid/user-grid.component';
import { UserRegistrationComponent } from './components/admin/users/user-registration/user-registration.component';
import { ConfigurationComponent } from './components/admin/configuration/configuration/configuration.component';
import { LoanEntriesComponent } from './components/admin/loan-entries/loan-entries.component';
import { HttpconfigInterceptor } from './_helpers/interceptor/httpconfig.interceptors';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AdminComponent,
    SampleRegistrationComponent,
    SampleGridComponent,
    LocateSampleComponent,
    DynamicGridComponent,
    ExpiringSampleComponent,
    UserGridComponent,
    UserRegistrationComponent,
    ConfigurationComponent,
    LoanEntriesComponent,
    UnauthorizedComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TabMenuModule,
    TabViewModule,
    DropdownModule,
    TooltipModule,
    InputTextModule,
    DialogModule,
    FormsModule,
    CalendarModule,
    TableModule,
    MultiSelectModule,
    CheckboxModule,
    ReactiveFormsModule,
    InputTextareaModule,
    NgxSpinnerModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpconfigInterceptor,
      multi: true,
    },
    FormGroupDirective, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
