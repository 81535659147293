import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthServiceService } from 'src/app/services/dataService/auth-service.service';
import { DataService } from 'src/app/services/dataService/data.service';
import { JWTTokenService } from 'src/app/services/dataService/jwttoken.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isError: boolean | undefined;
  returnUrl: string | undefined;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: DataService,
    private jwtservice: JWTTokenService,
    private authService: AuthServiceService,
    private spinner: NgxSpinnerService
  ) { }
  usertoken: any;
  notAuthorized = false;
  isloading = true;
  userData: any = {
    id: 0,
    basfuserid: '',
    first_name: '',
    last_name: '',
    email: '',
    is_admin: ''
  }

  _jwtToken: any;


  ngOnInit(): void {
    this.spinner.show();
    this.route.queryParams.subscribe((params) => {
      console.log(params, params['returnUrl'])
      if (params['returnUrl'] != undefined) {
        sessionStorage.setItem('returnUrl', params['returnUrl']);
      }
      //https://sampleretention-dev.basf.com/#/login?id=MA%3D%3D&EncUserBasfId=VGhvbGV0U00%3D&Email=c2FtYmEtbXVydGh5LW5haWR1LnRob2xldGlAcGFydG5lcnMuYmFzZi5jb20%3D&fn=U2FtYmEgTXVydGh5IE5haWR1&ln=VGhvbGV0aQ%3D%3D&isAdmin=RmFsc2U%3D
      //admin http://localhost:40912/#/login?id=MA%3D%3D&EncUserBasfId=VGhvbGV0U00%3D&Email=c2FtYmEtbXVydGh5LW5haWR1LnRob2xldGlAcGFydG5lcnMuYmFzZi5jb20%3D&fn=U2FtYmEgTXVydGh5IE5haWR1&ln=VGhvbGV0aQ%3D%3D&isAdmin=RmFsc2U%3D
      //non admin //http://localhost:40912/#/login?id=MA%3D%3D&EncUserBasfId=VGhvbGV0U00%3D&Email=c2FtYmEtbXVydGh5LW5haWR1LnRob2xldGlAcGFydG5lcnMuYmFzZi5jb20%3D&fn=U2FtYmEgTXVydGh5IE5haWR1&ln=VGhvbGV0aQ%3D%3D&isAdmin=RmFsc2U%3D
      let user = {
        id: params['id'] ? Number(atob(params['id'])) : 0,
        first_name: '',
        last_name: '',
        basfuserid: params['EncUserBasfId'] ? atob(params['EncUserBasfId']) : '',
        email: params['Email'] ? atob(params['Email']) : '',

        is_admin: params['isAdmin'] ? JSON.parse(atob(params['isAdmin']).toLowerCase()) : '',
      };
      
      console.log(user, "from params");
      if (user.basfuserid == undefined || user.basfuserid == '') {
        const isLoggedIn = sessionStorage.getItem('is_logged_in');
        console.log("isLoggedIn", isLoggedIn)
        if (isLoggedIn == 'true')
          this.redirectToPage();
        // Else Showing Login page with button to login
        this.spinner.hide();
      } else {
        this.jwtservice.RetrieveJWtToken1(user).subscribe({
          next: (result: any) => {
            console.log(result);
            if (result.token == "UnAuthorizedRegion") {
              this.spinner.hide();
              this.router.navigate(['/']);
              return;
            }
            sessionStorage.setItem('is_admin', user.is_admin);
            sessionStorage.setItem('userId', user.id.toString());
            sessionStorage.setItem('basfId', user.basfuserid.toString());
            sessionStorage.setItem('TokenInfo', JSON.stringify(result.token));
            localStorage.setItem('TokenInfo', JSON.stringify(result.token));
            this.service.setadmin(user.is_admin);
            let userloginData = {
              id: user.id,
              first_name: '',
              last_name: '',
              basf_user_id: user.basfuserid,
              email: user.email,

              is_admin: user.is_admin,
            };

            this.service.sendUserData(userloginData);
            this.redirectToPage();
          },
          error: (error: any) => {
            this.isError = true;
            console.error(error);
          },
        });
      }
    });
  }
  SAMLService() {
    sessionStorage.setItem('is_logged_in', 'true')
    this.service.getSAMLService().subscribe((response: any) => {
      this.spinner.hide();
      if (!response['IsError']) {
        this.router.navigateByUrl(response.response);

      };

    }
    );
  }

  redirectToPage() {
    this.spinner.show();
    console.log(sessionStorage.getItem('returnUrl'))
    if (sessionStorage.getItem('returnUrl') !== null)
      this.returnUrl = sessionStorage.getItem('returnUrl') as string;
    if (this.returnUrl == undefined || this.returnUrl == '') {
      if (sessionStorage.getItem('userId') + '' !== '0' && sessionStorage.getItem('userId') != undefined && sessionStorage.getItem('userId') != null)
        this.router.navigate(['/home']);
      else {
        sessionStorage.setItem('is_logged_in', 'false')
        this.router.navigate(['/unauthorised']);
      }
    }
    else {
      console.log(this.returnUrl)
      sessionStorage.removeItem('returnUrl');
      this.returnUrl = decodeURIComponent(this.returnUrl);
      this.router.navigateByUrl(this.returnUrl);
    }
    this.spinner.hide();
  }
}
