import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataService/data.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-expiring-sample',
  templateUrl: './expiring-sample.component.html',
  styleUrls: ['./expiring-sample.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpiringSampleComponent implements OnInit {
  cols: any;
  sampleData = [];
  sampleDataGrid = [];
  selectedRow = [];
  fromDateValue: any;
  toDateValue: any;
  constructor(
    private dataService: DataService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService
  ) { }
  expiringSampleForm = new FormGroup({
    fromDate: new FormControl('', Validators.required),
    toDate: new FormControl('', Validators.required),
  });
  ngOnInit() {
    this.loadData();
    this.generateHeaders();
    this.selectedCols = JSON.parse(JSON.stringify(this.cols));
    this.orgCol = JSON.parse(
      JSON.stringify(this.cols.filter((x: any) => x.is_show))
    );
  }

  showForm = false;
  selectedCols: any[] = [];
  orgCol: any;
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(
      JSON.stringify(this.dataService.sortSelectedColumns(this.selectedCols))
    );
  }

  exportExcel() {
    let data = {
      fromDate: '',
      toDate: '',
    };
    this.dataService.GetExpirySamples(data).subscribe((response) => {
      if (!response['IsError']) {
        this.formatData(response.response)
      }
    });
  }

  formatData(data: any) {
    {
      let tempData = data.map((item: any) => {
        let tempItem = {
          ...item,
          createdDate: this.datepipe.transform(
            item.createdDate,
            'MM/dd/yyyy'
          ),
          expirationDate: this.datepipe.transform(
            item.expirationDate,
            'MM/dd/yyyy'
          ),
        };
        return tempItem;
      });

      let tempUserdata = _.cloneDeep(tempData);
      const tempSample = tempUserdata.map((item: any) => {
        if (item.id) {
          delete item.id;
        }

        Object.keys(item).forEach((key: string) => {
          let keyName = this.cols.filter((item: any) => item.field == key);
          keyName = keyName.length > 0 ? keyName[0].header : key;
          item[keyName] = item[key] ? item[key].toString() : '';
          if (keyName.length > 0) {
            delete item[key];
          }
        });

        return { ...item };
      });
      this.dataService.exportExcel(tempSample, 'Expiring Samples');
    }
  }

  searchData() {
    let tempFromDate: any;
    let tempToDate: any;
    if (this.fromDateValue == undefined && this.toDateValue == undefined) {
      tempFromDate = '';
      tempToDate = '';
    } else if (
      this.fromDateValue == undefined ||
      this.toDateValue == undefined
    ) {
      this.fromDateValue == undefined
        ? this.toastr.warning('Please enter from date.')
        : this.toastr.warning('Please enter to date.');
      return;
    } else {
      console.log(this.fromDateValue);
      tempFromDate = new Date(this.fromDateValue);
      tempToDate = new Date(this.toDateValue);

      tempFromDate = `${tempFromDate.getMonth() + 1
        }/${tempFromDate.getDate()}/${tempFromDate.getFullYear()}`;
      tempToDate = `${tempToDate.getMonth() + 1
        }/${tempToDate.getDate()}/${tempToDate.getFullYear()}`;
    }
    let data = {
      fromDate: tempFromDate,
      toDate: tempToDate,
    };
    this.dataService.GetExpirySamples(data).subscribe((response) => {
      if (!response['IsError']) {
        this.sampleData = response.response;
        this.sampleDataGrid = _.cloneDeep(this.sampleData);
      }
    });
  }
  onSelectRow(rowObject: { rowData: any }) {
    console.log(rowObject);
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));
  }
  loadData() {
    let data = {
      fromDate: '',
      toDate: '',
    };
    this.spinner.show()
    this.dataService.GetExpirySamples(data).subscribe((response) => {
      this.spinner.hide()
      if (!response['IsError']) {
        let tempData = response.response.map((item: any) => {
          let tempItem = {
            ...item,
            createdDate: this.datepipe.transform(
              item.createdDate,
              'MM/dd/yyyy'
            ),
            expirationDate: this.datepipe.transform(
              item.expirationDate,
              'MM/dd/yyyy'
            ),
          };
          return tempItem;
        });
        this.sampleData = tempData;
        this.sampleDataGrid = _.cloneDeep(this.sampleData);
      }
    });
  }
  onSendSelectedRows(rowObject: any) {
    this.selectedRow = JSON.parse(JSON.stringify(rowObject));
    console.log(this.selectedRow);
  }
  cancel() {
    this.expiringSampleForm.reset();
    this.loadData();
  }
  deleteSelectedRow() {
    let data: any = this.selectedRow.map((item: any) => {
      return {
        Id: item.id,
        SequenceNo: item.sequenceNo,
        PalletNo: item.palletNo,
        BoxNo: item.boxNo,
      };
    });
    this.dataService.DeleteSamples(data).subscribe({
      next: (response) => {
        if (!response['IsError']) {
          this.toastr.success('Sample deleted successfully.');
          this.loadData();
          this.selectedRow = [];
        }
      },
      error: (error) => {
        this.toastr.warning(error.error);
      },
    });
  }
  generateHeaders() {
    this.cols = [
      {
        serial_no: 1,
        field: 'sequenceNo',
        header: 'Sequence No.',
        //expire sample
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 2,
        field: 'id',
        header: 'ID No.',
        //expire sample
        is_show: false,
        is_editable: true,
      },
      {
        serial_no: 3,
        field: 'palletNo',
        header: 'Pallete No.',
        //expire sample
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 4,
        field: 'boxNo',
        header: 'Box No.',
        //expire sample
        is_show: true,
        is_editable: true,
      },

      {
        serial_no: 6,
        field: 'createdDate',
        header: 'Created Date',
        //expire sample
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 7,
        field: 'expirationDate',
        header: 'Expiration Date',
        //expire sample
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 8,
        field: 'status',
        header: 'Status',
        //expire sample
        is_show: true,
        is_editable: true,
      },
    ];
  }
}
