<div class="side-padding" id="locateS">
  <div class="row" style="align-items: center; padding: 20px 0px">
    <label class="col-md-6 header-label">Available Samples</label>
  </div>
  <div class="row" style="background: white; margin: 0px">
    <div
      class="row"
      style="align-items: center; padding: 20px 0px; margin: 0px"
      [ngClass]="{ 'modal-backdrop-custom': showForm }"
    >
      <div class="col-md-6 mobileButtonClass">
        <button type="button" class="btn add-btn" (click)="showForm = true">
          Locate Sample
        </button>
        <button type="button" (click)="exportExcel()" class="btn excelButton">
          <em class="pi pi-file-excel"></em>Export
        </button>
      </div>
      <div class="col-md-6" style="text-align: end">
        <button
          type="button"
          class="btn add-btn"
          style="margin: 0px 10px; border-radius: 50px !important"
          [disabled]="selectedRow.length == 0"
          (click)="deleteSelectedRow()"
        >
          Delete Sample
        </button>
        <p-multiSelect
          id="showGridClass"
          [options]="orgCol"
          [(ngModel)]="selectedCols"
          name="colsDD"
          ngDefaultControl
          (onChange)="onChangeDDValue($any($event).value)"
          optionLabel="header"
          defaultLabel="Show items in grid"
          [displaySelectedLabel]="false"
        >
        </p-multiSelect>
      </div>
    </div>

    <div class="col-md-12" style="position: relative">
      <div *ngIf="showForm" class="custom-sidenav">
        <div
          class="row"
          style="align-items: center; color: white"
          [ngClass]="{ 'add-poniter-all': showForm }"
        >
          <label class="col-md-10 col-sm-10 col-10 header-label"
            >Locate Sample</label
          >
          <span
            class="col-md-2 col-sm-2 col-2"
            (click)="showForm = false"
            style="cursor: pointer; padding: 0px; text-align: center"
            >X</span
          >
          <div class="col-md-12">
            <form id="locateSampleForm" [formGroup]="locateForm">
              <div class="row">
                <div class="col-md-12">
                  <div class="row" style="align-items: inherit">
                    <div class="col-md-12 cust-form-group">
                      <div class="form-group">
                        <label class="cust-form-label" for="sequenceNo"
                          >Sequence No.</label
                        >
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          formControlName="sequenceNo"
                        />
                      </div>
                    </div>
                    <div class="col-md-12 cust-form-group">
                      <div class="form-group">
                        <label class="cust-form-label" for="palletNo"
                          >Pallet No.</label
                        >
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          formControlName="palletNo"
                        />
                      </div>
                    </div>
                    <div class="col-md-12 cust-form-group">
                      <div class="form-group">
                        <label class="cust-form-label" for="boxNo"
                          >Box No.</label
                        >
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          formControlName="boxNo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mobileButtonClass bottom-class">
                  <div
                    class="row"
                    style="
                      align-items: inherit;
                      text-align: center;
                      padding: 20px 0px;
                    "
                  >
                    <div class="col-md-12 cust-form-group">
                      <div>
                        <input
                          type="button"
                          value="Cancel"
                          class="btn cancel-btn"
                          (click)="showForm = false"
                        />
                        <input
                          type="button"
                          value="Submit"
                          class="btn submit-btn"
                          [disabled]="locateForm.invalid"
                          (click)="searchData()"
                          style="
                            border-top-right-radius: 0px !important;
                            border-bottom-right-radius: 0px !important;
                          "
                        />
                        <input
                          type="button"
                          value="Clear"
                          class="btn clear-btn"
                          (click)="cancel()"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row" [ngClass]="{ 'modal-backdrop-custom': showForm }">
        <div class="col-md-12">
          <app-dynamic-grid
            [gridCols]="cols"
            [gridData]="sampleData"
            [showFilter]="true"
            [checkboxShow]="true"
            (selectRow)="onSelectRow($event)"
            (sendSelectedRows)="onSendSelectedRows($event)"
          >
          </app-dynamic-grid>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>
