
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataService/data.service';

@Component({
  selector: 'app-sample-registration',
  templateUrl: './sample-registration.component.html',
  styleUrls: ['./sample-registration.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SampleRegistrationComponent {
  @Input() formData: any;
  @Input() formType: any;
  @Input() data: any;
  @Output() reLoadGrid = new EventEmitter();

  sampleForm = new FormGroup({
    box: new FormControl('', Validators.required),
    sample: new FormControl('', Validators.required),
    pallete: new FormControl('', Validators.required),
    date: new FormControl('', Validators.required),
  });
  lineObj = {
    id: 0,
    box: 0,
    sample: 0,
    pallete: 0,
    date: ''
  };
  apiRequest = {
    Id: 0,
    SequenceNo: 0,
    PalletNo: 0,
    BoxNo: 0,
    status: '',
    productionDate: new Date()
  };

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { }
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  ngOnChanges(): void {
    if (this.formType !== 'New') {
      this.lineObj = {
        id: this.formData['id'],
        sample: this.formData['sequenceNo'],
        pallete: this.formData['palletNo'],
        box: this.formData['boxNo'],
        date: this.formData['productionDate'],
      };
      this.sampleForm.controls['box'].setValue(this.formData['boxNo']);
      this.sampleForm.controls['sample'].setValue(this.formData['sequenceNo']);
      this.sampleForm.controls['pallete'].setValue(this.formData['palletNo']);
      this.sampleForm.get('date')?.setValue((this.formData['productionDate']));
    }
  }
 
  submitForm(flag: any) {
    console.log(this.sampleForm)
    if (this.sampleForm.invalid) {
      this.toastr.error('Please fill all the fields.');
      return false;
    }

    this.spinner.show();

    if (this.formType === 'New') {
      this.apiRequest['Id'] = 0;
    } else {
      this.apiRequest['Id'] = this.formData.id;
    }
    this.apiRequest['BoxNo'] = Number(this.sampleForm.value.box);
    this.apiRequest['SequenceNo'] = Number(this.sampleForm.value.sample);
    this.apiRequest['PalletNo'] = Number(this.sampleForm.value.pallete);
    this.apiRequest['status'] = flag;
    const date=new Date(this.sampleForm.value.date as string)
    date.setDate(date.getDate()+1)
    this.apiRequest['productionDate'] = date;
    console.log(this.apiRequest)
    this.dataService.SaveSamplePosition(this.apiRequest).subscribe({
      next: (response) => {
        this.spinner.hide();
        if (!response['IsError']) {
          this.toastr.success(
            this.formType == 'New'
              ? 'Sample added successfully.'
              : 'Sample updated successfully.'
          );
          this.reLoadGrid.emit(true);
        }
      },
      error: (error) => {
        this.spinner.hide();
        this.toastr.warning(error.error);
      },
    });
    return true;
  }

  cancel() {
    this.reLoadGrid.emit(false);
  }
}
