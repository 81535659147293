<nav  id="header_component" class="navbar navbar-expand-lg navbar-light bg-custom navbar-static-top">
  <div class="header-styling">
    <a class="navbar-brand" href="#">
      <div class="header-logo" style="display: inline-flex">
        <div>
          <img class="header-Logo-Basf" src="../../assets/images/BASFo_wh100tr.png" alt="BASF logo" loading="lazy" />
        </div>
        <div>
          <div style="display: inline-flex;align-items: center;">
            <div class="header-Logo-Dash"></div>
            <img class="header-Logo-Hand" src="../../assets/images/sales.png" alt="" loading="lazy" />
            <div class="header-logo-text">
              Sample
              <br />
              Retention
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
  <button class="navbar-toggler button-ha" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>

  </button>
  <div class="collapse navbar-collapse header-navbar" id="navbarNav" style="padding-right:6%">
    <ul class="navbar-nav ms-auto header-navbar-ul">
      <li class="nav-item" data-bs-target=".navbar-collapse" data-bs-toggle="collapse">
        <a [routerLink]="['/sample-entry']" class="nav-link" routerLinkActive="active"
           [routerLinkActiveOptions]="{ exact: false }">Sample Entry</a>
      </li>
      <li class="nav-item" data-bs-target=".navbar-collapse" data-bs-toggle="collapse">
        <a [routerLink]="['/locate-sample']" class="nav-link" routerLinkActive="active"
           [routerLinkActiveOptions]="{ exact: false }">Locate Sample</a>
      </li>
      <li class="nav-item" data-bs-target=".navbar-collapse" data-bs-toggle="collapse">
        <a [routerLink]="['/expire-sample']" class="nav-link" routerLinkActive="active"
           [routerLinkActiveOptions]="{ exact: false }">Expiring Sample</a>
      </li>
      <li class="nav-item" data-bs-target=".navbar-collapse" data-bs-toggle="collapse">
        <a [routerLink]="['/loan-entry']" class="nav-link" routerLinkActive="active"
           [routerLinkActiveOptions]="{ exact: false }">Loan Entries</a>
      </li>
      <li *ngIf="isadmin" class="nav-item" data-bs-target=".navbar-collapse" data-bs-toggle="collapse">
        <a [routerLink]="['/admin']" class="nav-link" routerLinkActive="active"
           [routerLinkActiveOptions]="{ exact: false }">Admin</a>
      </li>
      <li class="nav-item" data-bs-target=".navbar-collapse" data-bs-toggle="collapse">
        <a [routerLink]="['/']" routerLinkActive="active" (click)="navigateTo()" style="cursor: pointer;" class="nav-link"
           [routerLinkActiveOptions]="{ exact: false }">Logout</a>
      </li>
    </ul>
  </div>
</nav>
