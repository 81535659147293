import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class HttpconfigInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add token 
    let tokenInfo = JSON.parse(sessionStorage.getItem('TokenInfo') + '');
    //let tokenInfo = JSON.parse(localStorage.getItem('TokenInfo')!);
    
    if (!request.url.includes('add')) {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({
          headers: request.headers.set('Content-Type', 'application/json'),
        });
        request = request.clone({
          headers: request.headers.set('Accept', 'application/json'),
        });
        request = request.clone({
          headers: request.headers.set('Cache-Control', 'no-cache'),
        });
        request = request.clone({
          headers: request.headers.set('Pragma', 'no-cache'),
        });
        request = request.clone({
          headers: request.headers.set(
            'Expires',
            'Sat, 01 Jan 2000 00:00:00 GMT'
          ),
        });
        request = request.clone({
          headers: request.headers.set('If-Modified-Since', '0'),
        });
        
      }
      request = request.clone({
        headers: request.headers.set('Access-Control-Allow-Origin', ['https://localhost:44381', 'http://localhost:40912', 'https://sampleretention-qa.basf.com', 'https://sampleretention-dev.basf.com', 'https://sampleretention.basf.com', 'https://federation-qa.basf.com', 'https://federation.basf.com']),
      });
    }

    console.log('Token in interceptor is:', tokenInfo);

    if (tokenInfo != null)
      request = request.clone({
        headers: request.headers.set(
          'Authorization',
          `Bearer ${tokenInfo}`
        ),
      });

    
    console.log("before", request)

    return next.handle(request).pipe(
      tap({
        next: (event) => this.eventFunction(event),
        error: (error) => this.errorFunction(error),
      })
    );
  }

  eventFunction(event: any) {
    if (event instanceof HttpResponse) {
      console.log("after", event)
      if (event.body != null) {
        if (event.body['isError']) {
          this.spinner.hide();
          this.toaster.error(event.body['response']);
          return false;
        }
      }
    }
    return true;
  }

  errorFunction(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (  error.status == 400) {
        this.toaster.error('Error');
      } else if (error.status == 401 || error.status == 0 ) {
        this.toaster.error('Unauthorized');
      }
      this.spinner.hide();
    }
  }
}
