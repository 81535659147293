import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import * as _ from 'lodash';

import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/dataService/data.service';

@Component({
  selector: 'app-sample-grid',
  templateUrl: './sample-grid.component.html',
  styleUrls: ['./sample-grid.component.css'],
})
export class SampleGridComponent {
  cols: any;
  sampleData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  country: any = [];
  role: any = [];
  showPallet = false;

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
  ) { }

  generateHeaders() {
    this.cols = [
      {
        serial_no: 1,
        field: 'sequenceNo',
        header: 'Sequence No',
        //sample grid
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 2,
        field: 'palletNo',
        header: 'Pallet No',
        //sample grid
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 3,
        field: 'boxNo',
        header: 'Box No',
        //sample grid
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 4,
        field: 'status',
        header: 'Status',
        //sample grid
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 5,
        field: 'productionDate',
        header: 'Production Date',
        //sample grid
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 6,
        field: 'expirationDate',
        header: 'Expiration Date',
        //sample grid
        is_show: true,
        is_editable: true,
      },
    ];
  }
  ngOnInit() {
    this.loadData();
    this.generateHeaders();
    this.selectedCols = JSON.parse(JSON.stringify(this.cols));
    this.orgCol = JSON.parse(
      JSON.stringify(this.cols.filter((x: any) => x.is_show))
    );
  }

  selectedCols: any[] = [];
  orgCol: any;
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(
      JSON.stringify(this.dataService.sortSelectedColumns(this.selectedCols))
    );
  }

  exportExcel() {
    let tempUserdata = _.cloneDeep(this.sampleData);
    const tempSample = tempUserdata.map((item: any) => {
      if (item.id) {
        delete item.id;
      }

      Object.keys(item).forEach((key: string) => {
        let keyName = this.cols.filter((item: any) => item.field == key);
        keyName = keyName.length > 0 ? keyName[0].header : key;
        item[keyName] = item[key] ? item[key].toString() : '';
        if (keyName.length > 0) {
          delete item[key];
        }
      });

      return { ...item };
    });
    this.dataService.exportExcel(tempSample, 'Locate Samples');
  }

  loadData() {
    let data = {
      sequenceNo: 0,
      palletNo: 0,
      boxNo: 0,
    };
    this.spinner.show()
    this.dataService.GetSamplePositions(data).subscribe((response) => {
      this.spinner.hide();
      if (!response['IsError']) {
        response.response.forEach((formData: any) => {
          formData.productionDate = this.datePipe.transform(formData.productionDate, 'MM/dd/yyyy')
          formData.expirationDate = this.datePipe.transform(formData.expirationDate, 'MM/dd/yyyy')
        })
        this.sampleData = response.response;
      }
    });
  }

  onSelectRow(rowObject: { rowData: any }) {
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));
    console.log(this.selectedRow);
    this.formType = 'Edit';
    this.showForm = true;
    this.showPallet = false;
  }

  addNewSample() {
    this.selectedRow = {
      id: '',
      sequenceNo: '',
      palletNo: '',
      boxNo: '',
      date: ''
    };
    this.formType = 'New';
    this.showForm = true;
    this.showPallet = false;
  }

  refreshUsers(eventvalue: any) {
    if (eventvalue) {
      this.loadData();
    }
    this.showForm = false;
    this.showPallet = false;
  }

  onHidePallet(value: any) {
    console.log(value);
    this.showPallet = value;
  }
}
