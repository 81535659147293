<div class="row">
  <div class="col-md-12">
    <form id="sampleForm" [formGroup]="sampleForm">
      <div class="row">
        <div class="col-md-12">
          <div class="row" style="align-items: inherit">
            <div class="col-md-12 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="sample"
                  >Sample No.<sup
                    style="color: red; font-weight: 900; font-size: 12px"
                    ><b>*</b></sup
                  ></label
                >
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="sample"
                />
              </div>
            </div>
            <div class="col-md-12 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="pallete"
                  >Pallete No.<sup
                    style="color: red; font-weight: 900; font-size: 12px"
                    ><b>*</b></sup
                  ></label
                >
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="pallete"
                />
              </div>
            </div>
            <div class="col-md-12 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="box"
                  >Box No.<sup
                    style="color: red; font-weight: 900; font-size: 12px"
                    ><b>*</b></sup
                  ></label
                >
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="box"
                />
              </div>
            </div>

            <div class="col-md-12 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="pallete"
                  >Production Date<sup
                    style="color: red; font-weight: 900; font-size: 12px"
                    ><b>*</b></sup
                  ></label
                >
                <p-calendar 
                appendTo="body"
                          dateFormat="mm/dd/yy"
                          [showIcon]="true"
                          formControlName="date"
                          [style]="{ width: '100%', height: '28px' }"
                        >
                        </p-calendar>
              </div>
            </div>


          </div>
        </div>
        <div class="col-md-12 mobileButtonClass bottom-class">
          <div
            class="row"
            style="align-items: inherit; text-align: center; padding: 20px 0px"
          >
            <div class="col-md-12 cust-form-group">
              <div>
                <input
                  type="button"
                  value="Cancel"
                  class="btn cancel-btn"
                  (click)="cancel()"
                />
                <input
                  type="button"
                  value="Save"
                  class="btn save-btn"
                  (click)="submitForm('save')"
                  [disabled]="sampleForm.invalid"
                  (click)="sampleForm.invalid ? toastrAlert() : ''"
                />
                <input
                  type="button"
                  value="Submit"
                  class="btn submit-btn"
                  (click)="submitForm('submit')"
                  [disabled]="sampleForm.invalid"
                  (click)="sampleForm.invalid ? toastrAlert() : ''"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>
