import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthServiceService } from './services/dataService/auth-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  userAccess = false;
  title='RPV';
  cities: string[] = [];
  selectedCity: string = '';
  userId = '0';
  constructor(public router: Router, public authService: AuthServiceService) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.userAccess = false;
        if (
          event.url == '/' ||
          event.url == '/login-test' ||
          event.url == '/login' ||
          event.url == '/logout' ||
          event.url == '/registration' ||
          event.url == '/saml' ||
          event.url == '/error'
        ) {
          this.userAccess = false;
        } else {
          if (sessionStorage.getItem('userId') + '' !== '0' && sessionStorage.getItem('userId') != undefined && sessionStorage.getItem('userId') != null)
            this.userAccess = true;
          else
            this.userAccess = false
        }
      });
  }
}
