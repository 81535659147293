<div class="side-padding" id="palletConfig">
  <div>
    <div
      class="row"
      style="align-items: center; padding: 1rem 0rem; color: white"
    >
      <label class="col-md-11 col-sm-11 col-11 header-label"
        >Pallet Configuration</label
      >
      <span
        class="col-md-1 col-sm-1 col-1"
        (click)="showPallet.emit(false)"
        style="cursor: pointer; color: white; padding: 0px; text-align: center"
        >X</span
      >
      <div class="col-md-12">
        <form
          id="palletConfigurationForm"
          [formGroup]="palletConfigurationForm"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="row" style="align-items: inherit">
                <div class="col-md-10 col-xl-10 col-10 cust-form-group">
                  <div class="form-group">
                    <label class="cust-form-label" for="palletNo"
                      >No. of boxes allowed in pallet<sup
                        style="color: red; font-weight: 900; font-size: 12px"
                        ><b>*</b></sup
                      ></label
                    >
                    <input
                      attr.disabled="{{ palletNo }}"
                      type="number"
                      class="form-control form-control-sm"
                      formControlName="palletNo"
                    />
                  </div>
                </div>
                <div class="col-md-2 col-2 col-xl-2">
                  <img
                    (click)="editConfig('pallet')"
                    style="padding-top: 24px"
                    alt="edit"
                    class="accordion-img-icon pointer-cursor"
                    title="Edit"
                    src="./assets/images/icons/edit_icon.png"
                  />
                </div>
              </div>
              <div class="row" style="align-items: inherit">
                <div class="col-md-10 col-xl-10 col-10 cust-form-group">
                  <div class="form-group">
                    <label class="cust-form-label" for="boxNo"
                      >No. of samples allowed in box<sup
                        style="color: red; font-weight: 900; font-size: 12px"
                        ><b>*</b></sup
                      ></label
                    >
                    <input
                      attr.disabled="{{ boxNo }}"
                      type="number"
                      class="form-control form-control-sm"
                      formControlName="boxNo"
                    />
                  </div>
                </div>
                <div class="col-md-2 col-2 col-xl-2">
                  <img
                    (click)="editConfig('box')"
                    style="padding-top: 24px"
                    alt="edit"
                    class="accordion-img-icon pointer-cursor"
                    title="Edit"
                    src="./assets/images/icons/edit_icon.png"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-12 mobileButtonClass bottom-class">
              <div
                class="row"
                style="
                  align-items: inherit;
                  text-align: center;
                  padding: 20px 0px;
                "
              >
                <div class="col-md-12 cust-form-group">
                  <div>
                    <input
                      type="button"
                      value="Cancel"
                      class="btn cancel-btn"
                      (click)="cancel()"
                      [ngClass]="{ 'add-radius': !editConfiguration }"
                    />
                    <input
                      type="button"
                      value="Update"
                      class="btn submit-btn"
                      *ngIf="editConfiguration"
                      [disabled]="palletConfigurationForm.invalid"
                      (click)="updateData()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
