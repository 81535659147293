import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './components/admin/admin.component';
import { ExpiringSampleComponent } from './components/admin/expiringSample/expiring-sample/expiring-sample.component';
import { LoanEntriesComponent } from './components/admin/loan-entries/loan-entries.component';
import { LocateSampleComponent } from './components/admin/locateSample/locate-sample/locate-sample.component';
import { SampleGridComponent } from './components/admin/sample/sample-grid/sample-grid.component';
import { UserGridComponent } from './components/admin/users/user-grid/user-grid.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGaurd } from './_helpers/auth.guard';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: LoginComponent },
  { path: 'login', pathMatch: 'full', component: LoginComponent, data: { expectedurl: 'login' }, },
  
  { path: 'home', component: HomeComponent ,canActivate: [AuthGaurd],data: { expectedurl: 'home' },},
  { path: 'admin', component: AdminComponent,canActivate: [AuthGaurd], },
  { path: 'users', component: UserGridComponent,canActivate: [AuthGaurd], },
  { path: 'loan-entry', component: LoanEntriesComponent,canActivate: [AuthGaurd], },
  { path: 'expire-sample', component: ExpiringSampleComponent ,canActivate: [AuthGaurd],},
  { path: 'locate-sample', component: LocateSampleComponent,canActivate: [AuthGaurd], },
  { path: 'sample-entry', component: SampleGridComponent,canActivate: [AuthGaurd], },
  { path: 'unauthorised', pathMatch: 'full', component: UnauthorizedComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
