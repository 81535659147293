const BaseUrl = './api';

export const environment = {
  production: true,
  //sample
  SaveSamplePosition: BaseUrl + `/SamplePosition/SaveSamplePosition`,
  //locate
  GetSamplePositions: BaseUrl + `/SamplePosition/GetSamplePositions`,
  DeleteSamples: BaseUrl + `/SamplePosition/DeleteSamples`,
  //expiring
  GetExpirySamples: BaseUrl + `/SamplePosition/GetExpirySamples`,
  //pallet configuration
  GetPalletBoxConfiguration: BaseUrl + `/PalletBox/GetPalletBoxConfiguration`,
  UpdatePalletBoxConfiguration:
    BaseUrl + `/PalletBox/UpdatePalletBoxConfiguration`,
  //users
  GetAllUsers: BaseUrl + `/Users/GetAllUsers`,
  SaveUser: BaseUrl + `/Users/SaveUser`,
  DeleteUser: BaseUrl + `/Users/DeleteUser`,
  getallLoanEntries: BaseUrl + `/Loan/GetAllLoanDetails`,
  insertLoan: BaseUrl + `/Loan/InsertLoanDetails`,

  //login
  SAML: `${BaseUrl}/Saml/GetSamlURL`,
  getLogoutUrl: `${BaseUrl}/Saml/GetLogoutUrl`,
  GetUser: `${BaseUrl}/Users/GetUser`,
  retrieveJWTToken:`${BaseUrl}/Jwt/Token`,
  getUsersforLogin:`${BaseUrl}/Users/GetUser`,
  RetrieveUserDetailsByEmailId: `${BaseUrl}/Users/GetUserByEmail`,
};
