import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataService/data.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css'],
})
export class UserRegistrationComponent {
  @Input() formData: any;
  @Input() formType: any;
  @Input() data: any;
  @Output() reLoadGrid = new EventEmitter();

  userRegistrationForm = new FormGroup({
    basfUserId: new FormControl('', Validators.required),
    firstName: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z,\s]*$/),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z,\s]*$/),
    ]),
    emailAddress: new FormControl('', [Validators.required, Validators.email]),
    isAdmin: new FormControl(false, [Validators.required]),
  });

  apiRequest = {
    id: 0,
    basfUserId: '',
    firstName: '',
    lastName: '',
    email: '',
    isAdmin: false,
  };
  isAdmin = false;
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}
  toastrAlert() {
    this.toastr.warning('Please enter mandatory fields.');
  }
  ngOnChanges(): void {
    if (this.formType !== 'New') {
      this.userRegistrationForm.controls['basfUserId'].setValue(
        this.formData.basfUserId
      );
      this.userRegistrationForm.controls['firstName'].setValue(
        this.formData.firstName
      );
      this.userRegistrationForm.controls['lastName'].setValue(
        this.formData.lastName
      );
      this.userRegistrationForm.controls['emailAddress'].setValue(
        this.formData.email
      );
      this.isAdmin = this.formData.isAdmin;
    }
  }
  submitUser() {
    if (this.userRegistrationForm.invalid) {
      this.toastr.error('Please fill all the fields');
      return false;
    }

    this.spinner.show();

    if (this.formType === 'New') {
      this.apiRequest['id'] = 0;
    } else {
      this.apiRequest['id'] = this.formData.id;
    }
    this.apiRequest['basfUserId'] = this.userRegistrationForm.value.basfUserId as string;
    this.apiRequest['firstName'] = this.userRegistrationForm.value.firstName as string;
    this.apiRequest['lastName'] = this.userRegistrationForm.value.lastName as string;
    this.apiRequest['email'] = this.userRegistrationForm.value.emailAddress as string;
    this.apiRequest['isAdmin'] = this.isAdmin;

    this.dataService.SaveUser(this.apiRequest).subscribe({
      next: (response) => {
        this.spinner.hide();
        if (!response['IsError']) {
          this.toastr.success(
            `User ${this.formType == 'New' ? 'created' : 'updated'
            } successfully.`
          );
          let userId = sessionStorage.getItem('userId') + '';
          if (this.apiRequest['id'] != 0 && userId === this.apiRequest['id'].toString()) {
            sessionStorage.setItem('is_admin', 'false');
            this.dataService.setadmin(false);
          }
          this.reLoadGrid.emit(true);
        }

      },
      error: (error: any) => {
        this.spinner.hide();

        this.toastr.warning(error.error);
      },
    });

    return true;
  }

  cancel() {
    this.reLoadGrid.emit(false);
  }
}
