import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as saveAs from 'file-saver';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ApiResponse } from 'src/app/model/ApiResponse';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})

export class DataService {
  constructor(private http: HttpClient) {}

  
  // login functionality
  
  userData = new BehaviorSubject({
    id: 0,
    basf_user_id: '',
    first_name: '',
    last_name: '',
    email: '',
    created_by: '',
    is_active: true,
    country: [{ id: 0, userId: 0, countryId: 0 }],
    country_name: '',
    role: [{ id: 0, userId: 0, roleId: 0 }],
    role_name: '',
  });
  sendUserData(value: any) {
    this.userData.next(value);
  }
  getUserData(): Observable<any> {
    return this.userData.asObservable();
  }

  private isAdmin: BehaviorSubject<any> = new BehaviorSubject(false);

  public setadmin(value: any) {

    this.isAdmin.next(value);

  }
  public getadmin(): Observable<any> {

    return this.isAdmin.asObservable();

  }

  SaveSamplePosition(data: any) {
    const userId = sessionStorage.getItem("userId");
    console.log(data,"sample")
    return this.http.post<ApiResponse>(`${environment.SaveSamplePosition}?userId=${userId}`, data);
  }
  GetSamplePositions(data: any) {
    return this.http.get<ApiResponse>(
      environment.GetSamplePositions +
        `?sequenceNo=${data.sequenceNo}&palletNo=${data.palletNo}&boxNo=${data.boxNo}`
    );
  }
  GetAllSamplePositions() {
    return this.http.get<ApiResponse>(environment.GetSamplePositions);
  }
  DeleteSamples(data: any) {
    const userId = sessionStorage.getItem("userId");
    return this.http.post<ApiResponse>(`${environment.DeleteSamples}?userId=${userId}`, data);
  }
  GetExpirySamples(data: any) {
    return this.http.get<ApiResponse>(
      environment.GetExpirySamples +
        `?fromDate=${data.fromDate}&toDate=${data.toDate}`
    );
  }
  GetPalletBoxConfiguration() {
    return this.http.get<ApiResponse>(environment.GetPalletBoxConfiguration);
  }
  UpdatePalletBoxConfiguration(data: any) {
    const userId = sessionStorage.getItem("userId");
    return this.http.post<ApiResponse>(
      `${environment.UpdatePalletBoxConfiguration}?userId=${userId}`,
      data
    );
  }
  getSAMLService() {
    return this.http.get<ApiResponse>(environment.SAML);
  }

  GetAllUsers() {
    return this.http.get<ApiResponse>(environment.GetAllUsers);
  }
  DeleteUser(data: any) {
    const userId = sessionStorage.getItem("userId");
    return this.http.get<ApiResponse>(`${environment.DeleteUser}?id=${data}&userId=${userId}`);
  }
  getLoggedinUserData(email_id: string) {
    return this.http.get<any>(
      `${environment.RetrieveUserDetailsByEmailId}?EmailId=${email_id}`
    );
  }
  SaveUser(data: any) {
    const userId = sessionStorage.getItem("userId");
    return this.http.post<ApiResponse>(`${environment.SaveUser}?userId=${userId}`, data);
  }

  getLogoutUrl(userId: string) {
    return this.http
      .get(`${environment.getLogoutUrl}?BasfUserName=${userId}`)
      .pipe(map((res) => res));
  }

  GetLoanEntries() {
    return this.http.get(environment.getallLoanEntries).pipe(map((x) => x));
  }
  insertloandetails(data: any) {
    const userId = sessionStorage.getItem("userId");
    return this.http.post(`${environment.insertLoan}?userId=${userId}`, data).pipe(map((x) => x));
  }

  exportExcel(data: any, fileName = '') {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, fileName);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(data, fileName + EXCEL_EXTENSION);
  }

  sortSelectedColumns(columsnData: any[]) {
    return columsnData.sort(
      (a: { serial_no: number }, b: { serial_no: number }) => {
        return a.serial_no - b.serial_no;
      }
    );
  }
}
