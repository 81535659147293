import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataService/data.service';
@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css'],
})
export class ConfigurationComponent implements OnInit {
  cols: any;
  sampleData = [];
  selectedRow = [];
  editConfiguration = false;
  palletNo = true;
  boxNo = true;
  @Output() showPallet = new EventEmitter();
  constructor(
    private dataService: DataService,
    private toastr: ToastrService
  ) {}
  palletConfigurationForm = new FormGroup({
    palletNo: new FormControl(0, Validators.required),
    boxNo: new FormControl(0, Validators.required),
  });
  ngOnInit() {
    this.loadData();
  }
  loadData() {
    this.dataService.GetPalletBoxConfiguration().subscribe((response) => {
      if (!response['IsError']) {
        let tempData = response.response;
        for (const element of tempData) {
          if (element.configType == 'Pallet') {
            this.palletConfigurationForm.controls['palletNo'].setValue(
              element.configSize
            );
          } else if (element.configType == 'Box') {
            this.palletConfigurationForm.controls['boxNo'].setValue(
              element.configSize
            );
          }
        }
      }
    });
  }
  updateData() {
    let data = [
      {
        id: 1,
        configType: 'Pallet',
        configSize: this.palletConfigurationForm.get('palletNo')!.value,
      },
      {
        id: 2,
        configType: 'Box',
        configSize: this.palletConfigurationForm.get('boxNo')!.value,
      },
    ];
    this.dataService.UpdatePalletBoxConfiguration(data).subscribe({
      next: (response) => {
        if (!response['IsError']) {
          this.toastr.success('Configuration updated successfully.');
          this.palletConfigurationForm.controls['palletNo'].disable();
          this.palletConfigurationForm.controls['boxNo'].disable();
          this.editConfiguration = false;
        }
      },
      error: (error) => {
        this.toastr.warning(error.error);
      },
    });
  }
  editConfig(configType: any) {
    this.editConfiguration = true;
    if (configType == 'pallet')
      this.palletConfigurationForm.controls['palletNo'].enable();
    if (configType == 'box')
      this.palletConfigurationForm.controls['boxNo'].enable();
  }

  cancel() {
    this.showPallet.emit(false);
  }
}
