<div class="side-padding" id="loanEntry">
  <div class="row" style="align-items: center; padding: 20px 0px">
    <label class="col-md-6 header-label">Available Loan</label>
  </div>

  <div class="row" style="background: white; margin: 0px">
    <div
      class="row"
      style="align-items: center; padding: 20px 0px; margin: 0px"
      [ngClass]="{ 'modal-backdrop-custom': showForm }"
    >
      <div class="col-md-6 mobileButtonClass">
        <button type="button" class="btn add-btn" (click)="addNewLoan()">
          Create Loan
        </button>
        <button type="button" (click)="exportExcel()" class="btn excelButton">
          <em class="pi pi-file-excel"></em>Export
        </button>
      </div>
      <div class="col-md-6" style="text-align: end">
        <p-multiSelect
          id="showGridClass"
          [options]="orgCol"
          [(ngModel)]="selectedCols"
          name="colsDD"
          ngDefaultControl
          (onChange)="onChangeDDValue($any($event).value)"
          optionLabel="header"
          defaultLabel="Show items in grid"
          [displaySelectedLabel]="false"
        >
        </p-multiSelect>
      </div>
    </div>
    <div class="col-md-12" style="position: relative">
      <div *ngIf="showForm" class="custom-sidenav">
        <div class="row" style="align-items: center; color: white">
          <label class="col-md-11 col-sm-11 col-11 header-label"
            >{{ "New" }} Loan</label
          >
          <span
            class="col-md-1 col-sm-1 col-1"
            (click)="showForm = false"
            style="cursor: pointer; padding: 0px; text-align: center"
            >X</span
          >
        </div>
        <div class="col-md-12" [ngClass]="{ 'add-poniter-all': showForm }">
          <form id="loanform" [formGroup]="loanForm">
            <div class="row">
              <div class="col-md-12 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="isAdmin"
                    >Sample<sup
                      style="color: red; font-weight: 900; font-size: 12px"
                      ><b>*</b></sup
                    ></label
                  >
                  <p-dropdown
                    [filter]="true"
                    filterBy="sequenceNo"
                    formControlName="sampleId"
                    placeholder="Select Sample"
                    optionLabel="sequenceNo"
                    optionValue="id"
                    width="100%"
                    [options]="sampleDD"
                    [style]="{ width: '100%' }"
                  ></p-dropdown>
                </div>
              </div>
              <div class="col-md-6 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="isAdmin">
                    Loaner First Name<sup
                      style="color: red; font-weight: 900; font-size: 12px"
                      ><b>*</b></sup
                    ></label
                  >
                  <input
                    type="text"
                    formControlName="loanerFirstName"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-md-6 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="isAdmin">
                    Loaner Last Name<sup
                      style="color: red; font-weight: 900; font-size: 12px"
                      ><b>*</b></sup
                    ></label
                  >
                  <input
                    type="text"
                    formControlName="loanerlastName"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-md-9 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="isAdmin">
                    Loaner Email</label
                  >
                  <input
                    type="text"
                    formControlName="loanerEmail"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-md-3 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="isAdmin">
                    Loaner Basf ID</label
                  >
                  <input
                    type="text"
                    formControlName="loanerBasfId"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>

              <div class="col-md-6 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="isAdmin">
                    Loaner Department</label
                  >
                  <input
                    type="text"
                    formControlName="loanerDept"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-md-6 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="isAdmin">
                    Cost Center</label
                  >
                  <input
                    type="text"
                    formControlName="costCentre"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-md-4 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="isAdmin">
                    Start Date</label
                  >
                  <p-calendar
                    dateFormat="mm/dd/yy"
                    formControlName="startDate"
                    [style]="{ width: '100%', height: '28px' }"
                    [showIcon]="true"
                  >
                  </p-calendar>
                </div>
              </div>

              <div class="col-md-4 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="isAdmin"> End Date</label>
                  <p-calendar
                    dateFormat="mm/dd/yy"
                    formControlName="endDate"
                    [style]="{ width: '100%', height: '28px' }"
                    [showIcon]="true"
                  >
                  </p-calendar>
                </div>
              </div>
              <div class="col-md-4 cust-form-group" *ngIf="formType != 'New'">
                <div class="form-group">
                  <label class="cust-form-label" for="isAdmin"
                    >Return Loan</label
                  >
                  <p-checkbox
                    [binary]="true"
                    [(ngModel)] ="returnLoan"
                    formControlName="returnLoan"
                    inputId="binary"
                  >
                  </p-checkbox>
                </div>
              </div>
              <div class="col-md-12 cust-form-group">
                <div class="form-group">
                  <label class="cust-form-label" for="isAdmin"> Comments</label>
                  <textarea
                    pInputTextarea
                    formControlName="remarks"
                    [style]="{ width: '100%', height: '48px' }"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12 mobileButtonClass bottom-class">
                <div
                  class="row"
                  style="
                    align-items: inherit;
                    text-align: center;
                    padding: 20px 0px;
                  "
                >
                  <div class="col-md-12 cust-form-group">
                    <div>
                      <input
                        type="button"
                        value="Cancel"
                        class="btn cancel-btn"
                        (click)="cancel()"
                      />

                      <input
                        type="button"
                        value="Submit"
                        class="btn submit-btn"
                        (click)="submitForm()"
                        [disabled]="loanForm.invalid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row" [ngClass]="{ 'modal-backdrop-custom': showForm }">
        <div class="col-md-12">
          <app-dynamic-grid
            [gridCols]="cols"
            [gridData]="loanData"
            [showFilter]="true"
            [showEdit]="true"
            [showTooltip]="{ flag: true, col: 'remarks' }"
            (selectRow)="onSelectRow($event)"
            (sendSelectedRows)="onSendSelectedRows($event)"
          >
          </app-dynamic-grid>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>
