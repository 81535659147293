import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/dataService/data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent  implements OnInit {
  menuItems: any;
  isadmin = false;
  isadminstring: string | undefined;
  basfId: any;
  userId = '0';
  constructor(
    private service: DataService,
    private router: Router
  ) {
   
     
    
     
  }

  ngOnInit(): void {
    console.log(this.isadmin)
    this.userId = sessionStorage.getItem('userId') + '';
    this.basfId = sessionStorage.getItem('basfId');

    this.service.getadmin().subscribe((resp: any) => {

      this.isadmin = Boolean(resp);
    })


if(sessionStorage.getItem('is_admin')?.toLowerCase() == 'false')
      this.isadmin = false;
      else
      this.isadmin=true;
  }

  navigateTo() {
    
    sessionStorage.removeItem('is_admin');
    sessionStorage.removeItem('TokenInfo');
    sessionStorage.removeItem('is_logged_in');
    sessionStorage.removeItem('userId');
    localStorage.removeItem('TokenInfo');
    localStorage.removeItem('returnUrl');
    window.localStorage.clear();
    localStorage.clear();
    window.sessionStorage.clear();
    sessionStorage.clear();
    let userData = {
      id: 0,
      basf_user_id: '',
      email: '',
      isSubscribed: false,
      first_name: '',
      last_name: '',
      is_admin: false,
      is_subscribed: false,
      is_external: true,
      tokenInfo: ''
    };
    this.service.sendUserData(userData);
    //this.router.navigate(['/']);
    this.logout();
  }

  roleId:any=0;
  
  logout() {
    localStorage.clear();
    this.service.getLogoutUrl(this.basfId).subscribe({
      next: (result: any) => {
        console.log(result);
        window.location.href = result['strResponse'].toString();
      },
      error: (error: any) => {
        console.log('error ', error);
      },
    });
  }
}
