<div class="side-padding" id="expiringSamples">
  <div class="row" style="align-items: center; padding: 20px 0px">
    <label class="col-md-6 header-label">Expired Samples</label>
  </div>
  <div class="row" style="background: white; margin: 0px">
    <div
      class="row"
      style="align-items: center; padding: 20px 0px; margin: 0px"
      [ngClass]="{ 'modal-backdrop-custom': showForm }"
    >
      <div class="col-md-6 mobileButtonClass">
        <button type="button" class="btn add-btn" (click)="showForm = true">
          Filter
        </button>
        <button type="button" (click)="exportExcel()" class="btn excelButton">
          <em class="pi pi-file-excel"></em>Export
        </button>
      </div>
      <div class="col-md-6" style="text-align: end">
        <button
        type="button"
        class="btn add-btn"
        style="margin: 0px 10px; border-radius: 50px !important"
        [disabled]="selectedRow.length == 0"
        (click)="deleteSelectedRow()"
      >
        Delete Sample
      </button>
        <p-multiSelect
          id="showGridClass"
          [options]="orgCol"
          [(ngModel)]="selectedCols"
          name="colsDD"
          ngDefaultControl
          (onChange)="onChangeDDValue($any($event).value)"
          optionLabel="header"
          defaultLabel="Show items in grid"
          [displaySelectedLabel]="false"
        >
        </p-multiSelect>
      </div>
    </div>

    <div class="col-md-12" style="position: relative">
      <div *ngIf="showForm" class="custom-sidenav">
        <div
          class="row"
          style="align-items: center"
          [ngClass]="{ 'add-poniter-all': showForm }"
        >
          <label class="col-md-11 col-sm-11 col-11 header-label color-white"
            >Filter</label
          >
          <span
            class="col-md-1 col-sm-1 col-1"
            (click)="showForm = false"
            style="
              cursor: pointer;
              color: white;
              padding: 0px;
              text-align: center;
            "
            >X</span
          >
          <div class="col-md-12">
            <form id="expiringSampleForm" [formGroup]="expiringSampleForm">
              <div class="row">
                <div class="col-md-12">
                  <div class="row" style="align-items: inherit">
                    <div class="col-md-12 cust-form-group">
                      <div class="form-group">
                        <label
                          class="cust-form-label color-white"
                          for="fromDate"
                          >From Date<sup
                            style="
                              color: red;
                              font-weight: 900;
                              font-size: 12px;
                            "
                            ><b>*</b></sup
                          ></label
                        >
                        <p-calendar
                          [(ngModel)]="fromDateValue"
                          dateFormat="dd/mm/yy"
                          [maxDate]="toDateValue"
                          [showIcon]="true"
                          panelStyleClass="fromDateClass"
                          formControlName="fromDate"
                          [style]="{ width: '100%', height: '28px' }"
                        >
                        </p-calendar>
                      </div>
                    </div>
                    <div class="col-md-12 cust-form-group">
                      <div class="form-group">
                        <label class="cust-form-label color-white" for="toDate"
                          >To Date<sup
                            style="
                              color: red;
                              font-weight: 900;
                              font-size: 12px;
                            "
                            ><b>*</b></sup
                          ></label
                        >
                        <p-calendar
                          [(ngModel)]="toDateValue"
                          dateFormat="dd/mm/yy"
                          [minDate]="fromDateValue"
                          [showIcon]="true"
                          panelStyleClass="toDateClass"
                          formControlName="toDate"
                          [style]="{ width: '100%', height: '28px' }"
                        ></p-calendar>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mobileButtonClass bottom-class">
                  <div
                    class="row"
                    style="
                      align-items: inherit;
                      text-align: center;
                      padding: 20px 0px;
                    "
                  >
                    <div class="col-md-12 cust-form-group">
                      <div>
                        <input
                          type="button"
                          value="Cancel"
                          (click)="showForm = false"
                          class="btn cancel-btn"
                        />
                        <input
                          type="button"
                          value="Submit"
                          class="btn submit-btn"
                          [disabled]="expiringSampleForm.invalid"
                          (click)="searchData()"
                          style="
                            border-top-right-radius: 0px !important;
                            border-bottom-right-radius: 0px !important;
                          "
                        />
                        <input
                          type="button"
                          value="Clear"
                          class="btn clear-btn"
                          (click)="cancel()"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-12" [ngClass]="{ 'modal-backdrop-custom': showForm }">
        <app-dynamic-grid
          [showFilter]="true"
          [gridCols]="cols"
          [gridData]="sampleDataGrid"          
          [checkboxShow]="true"
          (selectRow)="onSelectRow($event)"
          (sendSelectedRows)="onSendSelectedRows($event)"
        >
        </app-dynamic-grid>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  color="#fff"
  type="line-scale"
></ngx-spinner>